import './App.css';
import FrameLayout from './FrameLayout/FrameLayout';

function App() {
  return (
    <FrameLayout>

    </FrameLayout>
  );
}

export default App;
